<script setup lang="ts">
interface Props {
  price?: number | null
  discount?: number | null
  correctedPrice?: number | null
  currency?: any
  currencyUsed?: any
  substract?: boolean
  noEmphasis?: boolean
  boldLine?: boolean
  firstLine?: boolean
  emphasisClasses?: string
}

const props = withDefaults(defineProps<Props>(), {
  price: undefined,
  discount: undefined,
  correctedPrice: undefined,
  currency: undefined,
  currencyUsed: undefined,
  substract: false,
  noEmphasis: false,
  boldLine: false,
  firstLine: false,
  emphasisClasses: 'text-pink-500',
})
</script>

<template>
  <tr
    :class="[props.boldLine ? 'font-semibold' : 'font-light']"
    class="align-top"
  >
    <!-- text and description -->
    <td
      :class="[props.firstLine ? 'pb-2' : 'py-2', { [props.emphasisClasses]: !props.noEmphasis && (props.substract || props.price === 0) }]"
    >
      <slot />

      <p
        v-if="$slots.description"
        class="text-sm font-light text-gray-500"
      >
        <slot name="description" />
      </p>
    </td>

    <!-- price -->
    <td
      v-if="props.price !== undefined"
      :class="[props.firstLine ? 'pb-2' : 'py-2']"
      class="whitespace-nowrap pl-2 text-right"
    >
      <span
        v-if="props.discount"
        class="block font-semibold line-through"
        :class="props.emphasisClasses"
      >{{ $n(props.discount, props.currency) }}</span>

      <span
        :class="{
          [props.emphasisClasses]: !props.noEmphasis && (props.substract || props.price === 0),
          'tabular-nums': props.price !== 0,
        }"
      >
        <template v-if="price === 0">
          {{ $t('commons.free') }}
        </template>
        <template v-else-if="props.price === null">
          {{ $t('commons.not_available') }}
        </template>
        <template v-else>
          {{ props.substract ? '-' : '' }} {{ $n(props.price, props.currency) }}
        </template>
      </span>
      <span
        v-if="props.correctedPrice"
        class="block font-light tabular-nums"
      >({{ $n(props.correctedPrice, props.currencyUsed || props.currency) }})</span>
    </td>
  </tr>
</template>
